<template>
  <div class="pass-box-wrapper" v-if="enteredPass !== challenge">
    <el-input
      placeholder="Ingrese la clave"
      v-model="enteredPass"
      autofocus="true"
    ></el-input>
    <div class="preview-icon has-icon" @click="grantAccess" v-if="false">
      <Icons
        class="icon delay-entry"
        icon="close"
        size="32"
        :color="'rgba(255,255,255,0.9)'"
      ></Icons>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PassBox',

  transition: 'page',

  props: [ 'challenge' ],

  components: {
    Icons: () => import('Components/base/Icons')
  },

  data () {
    return {
      showPassBox: false,
      enteredPass: '',
    }
  }
}
</script>

<style lang="scss">
.pass-box-wrapper {
  .el-input {
    max-width: 400px;
  }
}
</style>

<style scoped lang="scss">
.pass-box-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $gray-dark;
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  left: 0;
  height: 100vh;
}

</style>